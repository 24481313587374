const data =[
    {
        id:"1",
        country:"Afghanistan"
    },
    {
        id:"2",
        country:"Albania"
    },
    {
        id:"3",
        country:"Algeria"
    },
    {
        id:"4",
        country:"Andorra"
    },
    {
        id:"5",
        country:"Angola"
    },
    {
        id:"6",
        country:"Antigua and Barbuda"
    },
    {
        id:"7",
        country:"Argentina"
    },
    {
        id:"8",
        country:"Armenia"
    },
    {
        id:"9",
        country:"Australia"
    },
    {
        id:"10",
        country:"Austria"
    },
    {
        id:"11",
        country:"Azerbaijan"
    },
    {
        id:"12",
        country:"Bahamas"
    },
    {
        id:"13",
        country:"Bahrain"
    },
    {
        id:"14",
        country:"Bangladesh"
    },
    {
        id:"15",
        country:"Barbados"
    },
    {
        id:"16",
        country:"Belarus"
    },
    {
        id:"17",
        country:"Belgium"
    },
    {
        id:"18",
        country:"Belize"
    },
    {
        id:"19",
        country:"Benin"
    },
    {
        id:"20",
        country:"Bhutan"
    },
    {
        id:"21",
        country:"Bolivia"
    },
    {
        id:"22",
        country:"Bosnia and Herzegovina"
    },
    {
        id:"23",
        country:"Botswana"
    },
    {
        id:"24",
        country:"Brazil"
    },
    {
        id:"25",
        country:"Brunei"
    },
    {
        id:"26",
        country:"Bulgaria"
    },
    {
        id:"27",
        country:"Burkina Faso"
    },{
        id:"28",
        country:"Burundi"
    },
    {
        id:"29",
        country:"Côte d'Ivoire"
    },
    {
        id:"30",
        country:"Cabo Verde"
    },
    {
        id:"31",
        country:"Cambodia"
    },
    {
        id:"32",
        country:"Cameroon"
    },
    {
        id:"33",
        country:"Canada"
    },
    {
        id:"34",
        country:"Central African Republic"
    },
    {
        id:"35",
        country:"Chad"
    },
    {
        id:"36",
        country:"Chile"
    },
    {
        id:"37",
        country:"China"
    },
    {
        id:"38",
        country:"Colombia"
    },
    {
        id:"39",
        country:"Comoros"
    },
    {
        id:"40",
        country:"Congo (Congo-Brazzaville)"
    },
    {
        id:"41",
        country:"Costa Rica"
    },
    {
        id:"42",
        country:"Croatia"
    },
    {
        id:"43",
        country:"Cuba"
    },
    {
        id:"44",
        country:"Cyprus"
    },
    {
        id:"45",
        country:"Czechia (Czech Republic)"
    },
    {
        id:"46",
        country:"Democratic Republic of the Congo"
    },
    {
        id:"47",
        country:"Denmark"
    },
    {
        id:"48",
        country:"Djibouti"
    },
    {
        id:"49",
        country:"Dominica"
    },
    {
        id:"50",
        country:"Dominican Republic"
    },
    {
        id:"51",
        country:"Ecuador"
    },
    {
        id:"52",
        country:"Egypt"
    },
    {
        id:"53",
        country:"El Salvador"
    },
    {
        id:"54",
        country:"Equatorial Guinea)"
    },
    {
        id:"55",
        country:"Eritrea"
    },
    {
        id:"56",
        country:"Estonia"
    },
    {
        id:"57",
        country:"Eswatini (fmr. 'Swaziland')"
    },
    {
        id:"58",
        country:"Ethiopia"
    },
    {
        id:"59",
        country:"Fiji"
    },
    {
        id:"60",
        country:"Finland"
    },
    {
        id:"61",
        country:"France"
    },
    {
        id:"62",
        country:"Gabon"
    },
    {
        id:"63",
        country:"Gambia"
    },
    {
        id:"64",
        country:"Georgia"
    },
    {
        id:"65",
        country:"Germany"
    },
    {
        id:"66",
        country:"Ghana"
    },
    {
        id:"67",
        country:"Greece"
    },
    {
        id:"68",
        country:"Grenada"
    },
    {
        id:"69",
        country:"Guatemala"
    },
    {
        id:"70",
        country:"Guinea"
    },
    {
        id:"71",
        country:"Guinea-Bissau"
    },
    {
        id:"72",
        country:"Guyana"
    },
    {
        id:"73",
        country:"Haiti"
    },
    {
        id:"74",
        country:"Holy See"
    },
    {
        id:"75",
        country:"Honduras"
    },
    {
        id:"76",
        country:"Hungary"
    },
    {
        id:"77",
        country:"Iceland"
    },
    {
        id:"78",
        country:"India"
    },
    {
        id:"79",
        country:"Indonesia"
    },
    {
        id:"80",
        country:"Iran"
    },
    {
        id:"81",
        country:"Iraq"
    },
    {
        id:"82",
        country:"Ireland"
    },
    {
        id:"83",
        country:"Israel"
    },
    {
        id:"84",
        country:"Italy"
    },
    {
        id:"85",
        country:"Jamaica"
    },
    {
        id:"86",
        country:"Japan"
    },
    {
        id:"87",
        country:"Jordan"
    },
    {
        id:"88",
        country:"Kazakhstan"
    },
    {
        id:"89",
        country:"Kenya"
    },
    {
        id:"90",
        country:"Kiribati"
    },
    {
        id:"91",
        country:"Kuwait"
    },
    {
        id:"92",
        country:"Kyrgyzstan"
    },
    {
        id:"93",
        country:"Laos"
    },
    {
        id:"94",
        country:"Latvia"
    },
    {
        id:"95",
        country:"Lebanon"
    },
    {
        id:"96",
        country:"Lesotho"
    },
    {
        id:"97",
        country:"Liberia"
    },
    {
        id:"98",
        country:"Libya"
    },
    {
        id:"99",
        country:"Liechtenstein"
    },
    {
        id:"100",
        country:"Lithuania"
    },
    {
        id:"101",
        country:"Luxembourg"
    },
    {
        id:"101",
        country:"Macau"
    },
    {
        id:"102",
        country:"Madagascar"
    },
    {
        id:"102",
        country:"Malawi"
    },
    {
        id:"103",
        country:"Malaysia"
    },
    {
        id:"104",
        country:"Maldives"
    },
    {
        id:"105",
        country:"Mali"
    },
    {
        id:"106",
        country:"Malta"
    },
    {
        id:"107",
        country:"Marshall Islands"
    },
    {
        id:"108",
        country:"Martinique"
    },
    {
        id:"109",
        country:"Mauritania"
    },
    {
        id:"110",
        country:"Mauritius"
    },
    {
        id:"111",
        country:"Mayotte"
    },
    {
        id:"112",
        country:"Mexico"
    },
    {
        id:"113",
        country:"Micronesia"
    },
    {
        id:"114",
        country:"Moldova"
    },
    {
        id:"115",
        country:"Monaco"
    },
    {
        id:"116",
        country:"Mongolia"
    },
    {
        id:"117",
        country:"Montenegro"
    },
    {
        id:"118",
        country:"Montserrat"
    },
    {
        id:"119",
        country:"Morocco"
    },
    {
        id:"120",
        country:"Mozambique"
    },
    {
        id:"121",
        country:"Myanmar, Burma"
    },
    {
        id:"122",
        country:"Namibia"
    },
    {
        id:"123",
        country:"Nauru"
    },
    {
        id:"124",
        country:"Nepal"
    },
    {
        id:"125",
        country:"Netherlands"
    },
    {
        id:"126",
        country:"Netherlands antilles"
    },
    {
        id:"127",
        country:"New Caledonia"
    },
    {
        id:"128",
        country:"New Zealand"
    },
    {
        id:"129",
        country:"Nicaragua"
    },
    {
        id:"130",
        country:"Niger"
    },
    {
        id:"131",
        country:"Nigeria"
    },
    {
        id:"132",
        country:"Niue"
    },
    {
        id:"133",
        country:"North Macedonia"
    },
    {
        id:"134",
        country:"Northern MAriana Islands"
    },
    {
        id:"135",
        country:"Norway"
    },
    {
        id:"136",
        country:"Oman"
    },
    {
        id:"137",
        country:"Pakistan"
    },
    {
        id:"138",
        country:"Palau"
    },
    {
        id:"139",
        country:"Palestinian territories"
    },
    {
        id:"140",
        country:"Panama"
    },
    {
        id:"141",
        country:"Papua New Guinea"
    },
    {
        id:"142",
        country:"Paraguay"
    },
    {
        id:"143",
        country:"Peru"
    },
    {
        id:"144",
        country:"Philippines"
    },
    {
        id:"145",
        country:"Pitcairn Island"
    },
    {
        id:"146",
        country:"Poland"
    },
    {
        id:"147",
        country:"Portugal"
    },
    {
        id:"148",
        country:"Puerto Rico"
    },
    {
        id:"149",
        country:"Qatar"
    },
    {
        id:"150",
        country:"Reunion Island"
    },
    {
        id:"151",
        country:"Romania"
    },
    {
        id:"152",
        country:"Russian Federation"
    },
    {
        id:"153",
        country:"Rwanda"
    },
    {
        id:"154",
        country:"Saint Kitts and Nevis"
    },
    {
        id:"155",
        country:"Saint Lucia"
    },
    {
        id:"156",
        country:"Saint Vincent and the Grenadines"
    },
    {
        id:"157",
        country:"Samoa"
    },
    {
        id:"158",
        country:"San MArino"
    },
    {
        id:"159",
        country:"Sao Tome and Principe"
    },
    {
        id:"160",
        country:"Saudi Arabia"
    },
    {
        id:"161",
        country:"Senegal"
    },
    {
        id:"162",
        country:"Serbia"
    },
    {
        id:"163",
        country:"SeyChelles"
    },
    {
        id:"164",
        country:"Sierra Leone"
    },
    {
        id:"165",
        country:"Singapore"
    },
    {
        id:"166",
        country:"Slovakia (Slovak Republic)"
    },
    {
        id:"167",
        country:"Slovenia"
    },
    {
        id:"168",
        country:"Solomon Islands"
    },
    {
        id:"169",
        country:"Somalia"
    },
    {
        id:"170",
        country:"South Africa"
    },
    {
        id:"171",
        country:"South Sudan"
    },
    {
        id:"172",
        country:"Spain"
    },
    {
        id:"173",
        country:"Sri Lanka"
    },
    {
        id:"174",
        country:"Sudan"
    },
    {
        id:"175",
        country:"Suriname"
    },
    {
        id:"176",
        country:"Swaziland (Eswatini)"
    },
    {
        id:"177",
        country:"Sweden"
    },
    {
        id:"178",
        country:"Switzerland"
    },
    {
        id:"179",
        country:"Syria, Syrian arab Republic"
    },
    {
        id:"180",
        country:"Taiwan (Republic of China)"
    },
    {
        id:"181",
        country:"Tajikistan"
    },
    {
        id:"182",
        country:"Tanzania"
    },
    {
        id:"183",
        country:"Thailand"
    },
    {
        id:"184",
        country:"Tibet"
    },
    {
        id:"185",
        country:"Timor-Leste"
    },
    {
        id:"186",
        country:"Togo"
    },
    {
        id:"187",
        country:"Tokelau"
    },
    {
        id:"188",
        country:"Tonga"
    },
    {
        id:"189",
        country:"Trinidad and Tobago"
    },
    {
        id:"190",
        country:"Tunisia"
    },
    {
        id:"191",
        country:"Turkey"
    },
    {
        id:"192",
        country:"Turkmenistan"
    },
    {
        id:"193",
        country:"Turks and Caicos Islands"
    },
    {
        id:"194",
        country:"Tuvalu"
    },
    {
        id:"195",
        country:"Uganda"
    },
    {
        id:"196",
        country:"Ukraine"
    },
    {
        id:"197",
        country:"United Arab emirates"
    },
    {
        id:"198",
        country:"United Kingdom"
    },
    {
        id:"199",
        country:"United States"
    },
    {
        id:"200",
        country:"Uruguay"
    },
    {
        id:"201",
        country:"Uzbekistan"
    },
    {
        id:"202",
        country:"Vanuatu"
    },
    {
        id:"201",
        country:"Vatican City State"
    },
    {
        id:"202",
        country:"Venezuela"
    },
    {
        id:"203",
        country:"Vietnam"
    },
    {
        id:"204",
        country:"Virgin Islands(British)"
    },
    {
        id:"205",
        country:"Virgin Island (U.S)"
    },
    {
        id:"206",
        country:"Wallis and Futuna Islands"
    },
    {
        id:"207",
        country:"Western Sahara"
    },
    {
        id:"208",
        country:"Yemen"
    },
    {
        id:"209",
        country:"Zambia"
    },

    {
        id:"210",
        country:"Zimbabwe"
    },
    {
        id:"",
        country:""
    },
    {
        id:"",
        country:""
    },
    {
        id:"",
        country:""
    },
    {
        id:"",
        country:""
    },
    {
        id:"",
        country:""
    },

    
    

     


]

export default data