import focused from '../../images/focused.jpg';
import prayer from '../../images/prayer.jpg';
import balance from '../../images/balance.jpg';
import light from '../../images/light.jpg'


const blogData = [
    {
        id: 1,
        title: 'Be inspired, Be focused',
        text: [
            {
                content: "In the pursuit of our goals and dreams, inspiration acts as the spark that ignites our journey, while focus serves as the steady flame that keeps us moving forward. Together, they form a dynamic duo that paves the path to success. In a world filled with distractions and challenges, cultivating inspiration and maintaining focus are essential for achieving our aspirations.",
            },
            {
                content: "Inspiration is the fuel that drives us to dream big and reach for the stars. It can come from various sources — a captivating story, a remarkable individual, a breathtaking piece of art, or a profound experience. Whatever its origin, inspiration has the remarkable ability to awaken our passions, stir our creativity, and propel us into action.",
            },
            {
                content: "When we are inspired, our minds are infused with a sense of purpose and possibility. We envision the future we desire and are motivated to take the necessary steps to turn our dreams into reality. Inspiration fuels our determination in the face of adversity, reminding us of the greater meaning behind our endeavors."
            }
        ],
        image: focused,
        dateMonth: 6,
        dateYear: 2023,
        dateDay: 14
    },
    {
        id: 2,
        title: 'Keep the light burning',
        text: [
            {
                content: "In the darkness of life's challenges and uncertainties, it is easy to feel overwhelmed and lost. Yet, it is during these times that keeping the light burning within us becomes crucial. This light symbolizes hope, resilience, and the unwavering belief that brighter days lie ahead. By nurturing this light, we can navigate through the darkest of times and emerge stronger than before.",
            },
            {
                content: "Hope is a powerful force that resides within each of us. It is the belief that no matter how dire the circumstances may seem, there is always a glimmer of light at the end of the tunnel. Hope gives us the strength to persevere, the courage to face our fears, and the faith to believe in a better tomorrow.",
            },
            {
                content: "During times of adversity, it is important to cultivate hope within ourselves and others. This can be done through acts of kindness, words of encouragement, or simply by being a source of support for those in need. By nurturing hope, we not only uplift ourselves but also inspire those around us to keep moving forward."
            }
        ],
        image: light,
        dateMonth: 7,
        dateYear: 2023,
        dateDay: 20
    },
    {
        id: 3,
        title: 'Balanced living',
        text: [
            {
                content: "Living a balanced life offers numerous benefits for our physical, mental, and emotional health. It reduces stress, prevents burnout, and improves our overall quality of life. When we prioritize balance, we feel more fulfilled, energized, and resilient in the face of challenges.",
            },
            {
                content: "Achieving balance is an ongoing journey that requires self-awareness, intentionality, and commitment. It involves regularly assessing and adjusting our priorities to ensure alignment with our values and goals. While maintaining balance may present challenges at times, the rewards are well worth the effort.",
            }
        ],
        image: balance,
        dateMonth: 7,
        dateYear: 2024,
        dateDay: 29
    },
    {
        id: 4,
        title: 'Power of prayer',
        text: [
            {
                content: "Prayer is a deeply personal and profound practice that has been a part of human culture and spirituality for millennia. It is a way to connect with a higher power, express gratitude, seek guidance, and find comfort in times of need. In this blog, we explore the power of prayer and its impact on our well-being and spiritual journey.",
            },
            {
                content: "For many people, prayer is a source of strength and comfort during difficult times. It provides a sense of peace and reassurance, knowing that they are not alone and that there is a higher power watching over them. Prayer can be a source of solace in times of grief, a beacon of hope in times of despair, and a source of courage in times of uncertainty.",
            },
            {
                content: "Prayer is also a pathway to gratitude and reflection. It allows us to pause and appreciate the blessings in our lives, no matter how small. By expressing gratitude through prayer, we cultivate a mindset of abundance and positivity, which can have a profound impact on our overall well-being."
            }
        ],
        image: prayer,
        dateMonth: 8,
        dateYear: 2024,
        dateDay: 17
    }
]

export default blogData;